<template>
    <div>
        <commonHeader :current-index="0"></commonHeader>
        <div class="tabItems">
            <div class="item">
               <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item v-for="(item,index) in routerArr" :to="item.path" :key="index">{{item.title}}</el-breadcrumb-item>
                    <!-- <el-breadcrumb-item :to="{ path: '/' }">活动管理</el-breadcrumb-item> -->
                </el-breadcrumb>
            </div>
        </div>
        <div class="contations">
            <div class="conItem">
                <div class="title">{{obj.title}}</div>
                <div class="desc">作者：{{obj.author}}  发布时间：{{obj.time | toTime(obj.time)}}</div>
                <div class="conText">
                    <div v-html="context"></div>
                </div>
            </div>
        </div>
        <commonFooter></commonFooter>
    </div>
</template>

<script>
import { getNewsDetail } from '@/common/public.js'
import commonHeader from '@/components/commonHeader'
import commonFooter from '@/components/commonFooter'
export default {
    components:{
        commonHeader,commonFooter
    },
    data(){
        return{
            routerArr:[
                {title:'全部文章',path:'/news'},
                {title:'文章详情',path:''}
                ],
            id:'',
            obj:{},
            context:''
        }
    },
    created(){
        this.id = this.$route.query.id;
        this.getDetails()
    },
    methods:{
        getDetails(){
            let data = {
                id:this.id
            };
            getNewsDetail(data).then(res=>{
                console.log(res,'文章详情')
                this.obj = res.result.detail;
                this.context = res.result.detail.content.replace(/↵/g,"/n");
            })
            // this.text = res.result.domain.content.replace(/↵/g,"/n");
        }
    }
}
</script>

<style scoped lang="less">
.tabItems{
    width: 100%;
    border-top: 1px solid #eee;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .item{
        width: 1200px;
        height: 56px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.contations{
    width: 100%;
    padding: 32px 0;
    background: #f7f7f7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .conItem{
        width: 1200px;
        padding: 40px 0;
        background: #fff;
        .title{
            width: 100%;
            text-align: center;
            font-size: 24px;
            font-weight: 400;
            line-height: 33px;
            color: #333333;
            margin-bottom: 10px;
        }
        .desc{
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #333333;
        }
        .conText{
            width: 100%;
            padding: 0 23px;
            box-sizing: border-box;
        }
    }
}
</style>